import { Typography } from '@material-ui/core'
import FileSaver from 'file-saver'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React, { useState } from 'react'

import pick from 'lodash.pick'

import UserService from '../../services/user.service'
import ModalButtonWrap from '../modal/modalButton'
import { keys, find, omit } from 'lodash'
import constants from '../../constants'

import {
  ExportButton,
  BaseForm,
  ExportActions,
  CircularProgress
} from '../styled'
import { resolveValue } from '../inputs'
import { useSelector } from 'react-redux'

export default function(props) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchUsers, setSearchUsers] = useState('all')
  const [colsFilter, setColsFilter] = useState('all')
  const fields = useSelector(state => state.users.fields)
  const filteredFields = useSelector(state => state.users.filteredFields)
  const users = useSelector(state => state.users.users)
  const filteredUsers = useSelector(state => state.users.filteredUsers)
  const selectedUsers = useSelector(state => state.users.selectedUsers)
  const resolveValues = (fields, values) => {
    return keys(values).reduce((acc, k) => {
      const value = values[k];
      const field = find(fields, f => f.name === k);
      const val = resolveValue(field, value);
      return {...acc, [k]:val};
    }, {});
  }

  // Add fake fields to keep in user values ShortUrl and Registered properties
  const fakeEncryptedUrlField = {
    name: 'ShortUrl',
    field_type: 'text'
  };
  const fakeRegiteredField = {
    name: 'Registered',
    field_type: 'text'
  };
  const fieldsEx = [ ...fields, fakeEncryptedUrlField, fakeRegiteredField ];
  const filteredFieldsEx = [ ...filteredFields, fakeEncryptedUrlField, fakeRegiteredField ];

  const numberToLetters = num => {
    let letters = ''
    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }
    return letters
}

  const exportUsersToCsv = () => {
    setLoading(true)
    const fieldsName = (colsFilter === 'all' ? fieldsEx : filteredFieldsEx).map(
      field => field.name
    )
    const header = fieldsName.reduce((acc, a, i) => {
      acc[numberToLetters(i)] = a
      return acc
    }, {});
    const usersToExport = searchUsers => {
      switch(searchUsers) {
        case 'search': return filteredUsers;
        case 'select': return selectedUsers.map(su => ({id: su.id, values: omit(su, ['id'])}));
        case 'all': return users;
        default: return [];
      }
    }
    UserService.exportToCsv([
      {
        name: 'users',
        content: [
          header,
          ...(usersToExport(searchUsers)).map(user => {
            const userValues = resolveValues(fieldsEx, user.values);
            const values = Object.values(pick(userValues, fieldsName))
            return values.reduce((acc, a, i) => {
              acc[numberToLetters(i)] = a.toString() // Needed for boolean values
              return acc
            }, {})
          })
        ]
      }
    ])
      .then(blob => {
        FileSaver.saveAs(blob, `users_${constants.session.getId()}.xlsx`)
        setLoading(false)
        setOpen(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <ModalButtonWrap
      onBlur={() => setOpen(false)}
      open={open}
      content={
        <BaseForm>
          <Typography gutterBottom>Souhaitez-vous exporter...</Typography>
          <RadioGroup
            aria-label="Type"
            name="type"
            row
            value={searchUsers}
            onChange={(_, val) => setSearchUsers(val)}
          >
            <FormControlLabel value="all" control={<Radio />} label="Tout" />
            <FormControlLabel
              value="search"
              control={<Radio />}
              label="La recherche en cours"
            />
            <FormControlLabel
              value="select"
              control={<Radio />}
              label="Les lignes sélectionnées"
            />
          </RadioGroup>

          <Typography gutterBottom>
            Sélectionner les colonnes à exporter :
          </Typography>

          <RadioGroup
            aria-label="Type"
            name="type"
            row
            value={colsFilter}
            onChange={(_, val) => setColsFilter(val)}
          >
            <FormControlLabel value="all" control={<Radio />} label="Toutes" />
            <FormControlLabel
              value="search"
              control={<Radio />}
              label="Les colonnes affichées"
            />
          </RadioGroup>

          <ExportActions>
            <ExportButton disabled={loading} onClick={exportUsersToCsv}>
              EXPORTER
              {loading && <CircularProgress size={24} />}
            </ExportButton>
          </ExportActions>
        </BaseForm>
      }
    >
      <ExportButton onClick={() => setOpen(true)}>
        EXPORTER LA LISTE
      </ExportButton>
    </ModalButtonWrap>
  )
}
